<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <div>
          <label>{{ $t("paiement.selectionnerMois") }}</label>
          <a-month-picker
            :placeholer="$t('paiement.selectionnerMois')"
            style="width: 300px; margin-left: 15px"
            format="MM-YYYY"
            @change="handleChangeMois"
          />
          <a-button type="reset" @click="resetFilter" style="float: right">
            <a-icon type="control" @click="resetFilter" />{{
              $t("action.reinitialiser")
            }}
          </a-button>
        </div>
        <a-button type="file-pdf" @click="generatePdf" style="float: right">
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
      </div>
    </div>
    <div>
      <a-table
        id="myTable"
        :pagination="true"
        :data-source="data"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
        <template slot="month" slot-scope="text">{{
          moment(text).format("DD/MM/YYYY")
        }}</template>
      </a-table>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import moment from "moment";
import $ from "jquery";
import axios from "axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import apiClient from "@/services/axios";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
function tableToJson(table) {
  var data = [];

  // first row needs to be headers
  var headers = [];
  for (var i = 0; i < table.rows[0].cells.length; i++) {
    headers[i] = table.rows[0].cells[i].innerHTML
      .toLowerCase()
      .replace(/ /gi, "");
  }

  // go through cells
  for (var i = 1; i < table.rows.length; i++) {
    var tableRow = table.rows[i];
    var rowData = {};

    for (var j = 0; j < tableRow.cells.length; j++) {
      rowData[headers[j]] = tableRow.cells[j].innerHTML;
    }

    data.push(rowData);
  }

  return data;
}

const fileList = [
  {
    uid: "-1",
    name: "EdTrust",
    status: "done",
    url: "../../../resources/images/eleve.jpg",
  },
];

export default {
  created() {
    apiClient
      .post("/employees/filter", {
        status: "active",
      })
      .then((res) => (this.employeeList = res.data))
      .catch();
    apiClient
      .post("/employee_payments/filter", {
        query: { bank: true },
      })
      .then((res) => {
        this.data = res.data;
        this.rowData = this.data.map((item) => ({ ...item }));
        this.cacheData = this.data.map((item) => ({ ...item }));
      })
      .catch();
  },
  computed: mapState(["settings"]),
  data() {
    return {
      editingKey: "",
      visibleParentFile: false,
      rowData: [],
      data: [],
      employeeList: [],
      form: this.$form.createForm(this),
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewImage: "",
      fileList,
      loadingPaiement: false,
      visibleModal: false,
      columns: [
        {
          title: this.$t("virement.rib"),
          dataIndex: "employee.RIB",
          key: "rib",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "rib",
          },
          onFilter: (value, record) =>
            record.rib.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.nom"),
          dataIndex: "employee.firstName",
          key: "firstName",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "firstName",
          },
          onFilter: (value, record) =>
            record.firstName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("enseignant.prenom"),
          dataIndex: "employee.lastName",
          key: "lastName",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "lastName",
          },
          onFilter: (value, record) =>
            record.lastName.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("virement.salaire"),
          dataIndex: "salary",
          key: "salary",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "salary",
          },
          onFilter: (value, record) =>
            record.salary.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("virement.nbJours"),
          dataIndex: "dayNbr",
          key: "dayNbr",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "dayNbr",
          },
          onFilter: (value, record) =>
            record.dayNbr.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("virement.mois"),
          dataIndex: "month",
          key: "month",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "month",
          },
          onFilter: (value, record) =>
            record.month.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("virement.etatPaiement"),
          dataIndex: "paymentStatus",
          key: "paymentStatus",
          width: "100px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "paymentStatus",
          },
          onFilter: (value, record) =>
            record.paymentStatus.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
    };
  },
  methods: {
    moment,
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    resetFilter() {
      this.data = this.rowData.filter((e) => e);
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    save(key) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.filter((item) => key === item.key)[0];
      const targetCache = newCacheData.filter((item) => key === item.key)[0];
      if (target && targetCache) {
        delete target.editable;
        this.data = newData;
        Object.assign(targetCache, target);
        this.cacheData = newCacheData;
      }
      this.editingKey = "";
    },
    cancel(key) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => key === item.key)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    onDelete(key) {
      const dataSource = [...this.data];
      this.data = dataSource.filter((item) => item.key !== key);
    },
    addPaiement(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          apiClient.put("/employee_payments", { data: values }).then((res) => {
            this.rowData.push({ ...res.data });
            this.data = this.rowData.map((item) => ({ ...item }));
            this.visibleModal = false;
            this.form.resetFields();
            this.$message.success(this.$t("success.paiementAjout"));
          });
        } else this.$message.warning(this.$t("warning.verifieDonnees"));
      });
    },
    handleCancel(e) {
      this.visibleModal = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    getMock() {
      const targetKeys = [];
      const mockData = [];
      for (let i = 0; i < 20; i++) {
        const data = {
          key: i.toString(),
          title: `content${i + 1}`,
          description: `description of content${i + 1}`,
          chosen: Math.random() * 2 > 1,
        };
        if (data.chosen) {
          targetKeys.push(data.key);
        }
        mockData.push(data);
      }
      this.mockData = mockData;
      this.targetKeys = targetKeys;
    },
    generatePdf() {
      this.$gtag.event("Imp virementBancaire personnels", {
        event_category: "Impression PDF",
        event_label: "Personnel - virementBancaire section",
        value: 1,
      });

      let table = $(".ant-table-fixed");
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF("l", "mm", "a3");
      doc.setFont("Amiri");
      var date = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(150, 70, "ETAT DE PAIE PERSONNELS");
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(350, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      let counter = 1;
      var count = 0;
      const array = [];
      for (let i = 0; i < this.data.length; i++) {
        arr.push(counter);
        arr.push(
          this.data[i].employee.firstName + " " + this.data[i].employee.lastName
        );
        arr.push(this.data[i].employee.cin);
        arr.push(this.data[i].employee.RIB);
        arr.push(this.data[i].salary);
        arr.push(this.data[i].loan);
        array.push(arr);
        arr = [];
        count = count + parseInt(this.data[i].salary);
        counter++;
      }

      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        margin: { top: 80 },
        head: [["N°", "Nom complet", "CIN", "RIB", "SALAIRE", "Avance"]],
        body: array,
        foot: [
          [
            "Total des salaires:",
            {
              colSpan: 4,
              content: writtenNumber(count, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            count,
          ],
        ],
      });

      doc.save("table.pdf");
    },
    showModal() {
      this.visibleModal = true;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChangeImage({ fileList }) {
      this.fileList = fileList;
    },
    handleChangeMois(value) {
      this.data = this.rowData.filter((elem) => {
        return (
          moment(elem.month).isSame(value, "year") &&
          moment(elem.month).isSame(value, "month")
        );
      });
    },
    handleChangeSelect1(value) {},
    handleChangeSelect2(value) {},
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
.editable-row-operations a {
  margin-right: 8px;
}
.switch {
  float: right;
  margin-right: 15px;
}
.bottom {
  margin-top: 7px;
}
label {
  width: 300px;
  line-height: 26px;
  margin-bottom: 20px;
}

a-input {
  height: 25px;
  width: 50%;
  margin-left: 50px;
}
.span {
  /* display: flex;  */
  flex-direction: row;
}
.space {
  margin-left: 200px;
}
.inp {
  margin-left: 0px;
}
.clearfix {
  float: right;
}
</style>
